<template>
  <div>
    <div class="Login base-height">
      <div class="login_left"></div>
      <div class="mainBox">
        <h1>
          <router-link to="/">
            <img src="@/assets/images/reviewmind/logo2.png" alt="로고" />
          </router-link>
        </h1>
        <h2>{{ $t("findId") }}</h2>

        <div class="form login">
          <fieldset>
            <legend>로그인</legend>
            <div class="cover bg">
              <input
                v-model="username"
                type="text"
                :placeholder="$t('username')"
              />
            </div>
            <div class="cover bg">
              <div class="phone">
                <input
                  v-model="mobileNo"
                  type="text"
                  :placeholder="$t('findId_mobile')"
                />
              </div>
            </div>
            <div class="cover bg">
              <button
                type="button"
                class="btn primary findEmail"
                @click="submit"
              >
                {{ $t("findId") }}
              </button>
            </div>
          </fieldset>
        </div>
      </div>
      <!-- <aside class="flexL">
        <router-link to="/login" class="btn">로그인</router-link>
      </aside> -->
    </div>
  </div>
</template>

<script>
import { findUserEmail } from "@/api/member";
import { format } from "@/mixins/format";
import { setFooter } from "@/mixins/setFooter";
import { setNavbar } from "@/mixins/setNavbar";

export default {
  mixins: [format, setNavbar, setFooter],
  data() {
    return {
      username: "",
      mobileNo: "",
    };
  },
  mounted() {
    this.setNavbar(false);
    this.setFooter(false);
  },

  methods: {
    submit() {
      if (this.username == "") {
        return alert("이름을 입력해 주세요.");
      } else if (this.mobileNo == "") {
        return alert("번호를 입력해 주세요.");
      }

      let data = {
        mobileNo: this.mobileNo,
        username: this.username,
      };
      findUserEmail(data).then((res) => {
        if (res.data.status === 200) {
          let email = res.data.data;
          this.$router.push("/findEmailDone");
          this.$router.push({
            name: "findEmailDone",
            query: { id: email },
          });
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
  },
  beforeDestroy() {
    this.setNavbar(true);
    this.setFooter(true);
  },
};
</script>
